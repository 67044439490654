import { InjectionKey } from "vue";
import { DomainConfiguration } from "./runtime-configuration/DomainConfiguration";
import { Monitoring } from "@cp-common/frontend-monitoring";
import { CardDataService } from "@src/services/CardDataService";
import { FeatureApiService } from "@src/services/FeatureApiService";

export const DomainConfigurationKey: InjectionKey<DomainConfiguration> = Symbol(
  "DomainConfiguration"
);

export const MonitoringServiceKey: InjectionKey<Monitoring> =
  Symbol("MonitoringService");

export const CardDataServiceKey: InjectionKey<CardDataService> =
  Symbol("CardDataService");

export const FeatureToggleServiceKey: InjectionKey<FeatureApiService> = Symbol(
  "FeatureToggleService"
);
// TODO: Перенести сюда все provid'ы
