import { App as AppType, createApp, defineAsyncComponent } from "vue";
import App from "./App/App.vue";
import { langPlugin } from "@src/langSetup";
import { vMaska } from "maska";

import router from "./router";

import "./scss/main.scss";
import "@cloudpayments/vue-ui-kit/dist/style.css";
import "@cloudpayments/vue-ui-presets/dist/style.css";

import { applyDirectives, SkeletonDirective } from "@cloudpayments/vue-ui-kit";
import { Logger } from "@cloudpayments/vue-utils";
import {
  CardDataServiceKey,
  DomainConfigurationKey,
  FeatureToggleServiceKey,
  MonitoringServiceKey,
} from "@src/injections";
import {
  IMonitoringParams,
  isMobile,
  Monitoring,
} from "@cp-common/frontend-monitoring";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import "@tinkoff/statist-browser-typed-client-cloud.payments.widget";
import { environment } from "./environment";
import { domainConfigurationMock } from "./domainConfigurationMock";
import { CardDataService } from "@src/services/CardDataService";
import { FeatureApiService } from "./services/FeatureApiService";

const app = createApp(App);

const registerGlobalComponents = (
  app: AppType<Element>,
  componentsArray: { name: string; path: string }[]
) => {
  componentsArray.forEach((cmp) => {
    app.component(
      cmp.name,
      defineAsyncComponent(() => import(`@src/icons/secure/${cmp.path}.vue`))
    );
  });
};

function loadDomainConfig() {
  if (process.env.NODE_ENV != "production") {
    return Promise.resolve(domainConfigurationMock);
  }
  return fetch(environment.configPath)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to load configuration");
    })
    .then((response) => response.data);
}

loadDomainConfig()
  .then((domainConfig: DomainConfiguration) => {
    Logger.LogInfo("Configuration", domainConfig);
    applyDirectives(app, [SkeletonDirective]);

    app.provide(DomainConfigurationKey, domainConfig);
    app.provide(CardDataServiceKey, new CardDataService());
    app.provide(FeatureToggleServiceKey, new FeatureApiService());
    registerGlobalComponents(app, domainConfig.components);
    registerMonitoringService(app, domainConfig);

    app.use(router);
    app.use(langPlugin, domainConfig);
    app.mount("#app");
    app.directive("maska", vMaska);
  })
  .catch((e) => console.log(e));

const registerMonitoringService = (
  app: AppType<Element>,
  domainConfig: DomainConfiguration
): void => {
  const monitoringParams: IMonitoringParams = {
    projectName: "Orders",
    userIdentifyParams: {},
    apiKey: domainConfig.monitoringSettings?.apiKey,
    host: domainConfig.monitoringSettings?.host,
  };

  if (domainConfig.amplitudeSettings?.apiKey) {
    monitoringParams.amplitude = {
      apiKey: domainConfig.amplitudeSettings?.apiKey,
    };
  }

  if (domainConfig.statistSettings?.enabled) {
    monitoringParams.statist = {
      projectName: domainConfig.statistSettings?.appName as any,
      params: {
        environment: domainConfig.order.isProd ? "prod" : "test",
        clientParameters: {},
      },
      userProperties: {
        isMobile: isMobile(),
        region: domainConfig.monitoringSettings?.region as "ru",
        project: "Orders",
      },
    };
  }

  const monitoring = new Monitoring(monitoringParams);
  app.provide(MonitoringServiceKey, monitoring);
};
