<template src="./Home.html"></template>
<script lang="ts">
import {
  ComponentInternalInstance,
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  nextTick,
  onMounted,
  ref,
} from "vue";
import {
  Card,
  Header,
  LoaderService,
  Table,
  Text,
  Button,
  CardIcon,
} from "@cloudpayments/vue-ui-kit";

import { FeatureToggleServiceKey } from "@src/injections";

import { Logger } from "@cloudpayments/vue-utils";
import { PaymentMethodEnum } from "@src/contracts/PaymentForm";
import PaymentProcess from "@src/components/PaymentProcess/PaymentProcess.vue";
import OrderHeader from "@src/components/OrderHeader/OrderHeader.vue";
import OrderContents from "@src/components/OrderContents/OrderContents.vue";
import { AppService } from "@src/services/AppService";
import { useRoute, useRouter } from "vue-router";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";
import { OrderOptions } from "@src/contracts/OrderOptions";
import { Lang } from "@src/contracts/LangEnum";
import { DomainConfigurationKey, MonitoringServiceKey } from "@src/injections";
import { DownBlueIcon } from "@src/icons/AsyncIcons";

const cardMap: Record<string, string> = {
  AmericanExpress: "american-express",
  DinersClub: "diners",
};

export default defineComponent({
  name: "Home",
  components: {
    Card,
    Header,
    Text,
    Table,
    PaymentProcess,
    OrderContents,
    OrderHeader,
    Button,
    CardIcon,
    DownBlueIcon,
  },
  setup() {
    const loader = inject<LoaderService>("LoaderService");
    const appService = inject<AppService>("AppService");
    const runtimeConfiguration = inject(DomainConfigurationKey)!;
    const monitoring = inject(MonitoringServiceKey);
    const featureService = inject(FeatureToggleServiceKey);

    let app: ComponentInternalInstance | null;
    const isOrderLoaded = ref(false);
    const showProcess = ref(true);
    const successPaymentMethod = ref<PaymentMethodEnum | null>(null);
    const failPaymentMethod = ref<PaymentMethodEnum | null>(null);
    const isCharity = ref();
    const route = useRoute();
    const router = useRouter();

    const order = ref<OrderOptions>();

    const transactionInfo = ref();
    const isOpenDetails = ref(false);
    const localizedAuthDate = ref();
    const locale = appService?.getOrderOptions()?.cultureName || "en-EN";

    onMounted(async () => {
      app = getCurrentInstance();
      try {
        await getOrder();
      } catch {
        await router.push({
          name: "ErrorPage",
        });
      }
    });

    const showContent = computed(() => {
      return (
        !isOrderLoaded.value ||
        (isOrderLoaded.value && order.value?.items?.items != null)
      );
    });

    async function getOrder() {
      const id = String(route?.params?.id);
      const orderData = await appService?.getOrder(id);

      try {
        await featureService?.loadToggleData(
          "/orders/translates/save-card-checkbox-label",
          orderData?.terminalPublicId
        );
      } catch {
        Logger.LogError("save card toggle label data loading error");
      }

      await changeLocale(orderData!.cultureName);
      order.value = orderData;

      if (order.value) {
        document.title = order.value.description;

        monitoring?.sendAmplitudeEvent(AmplitudeEvents.OrdersOpen, {
          AccountId: order.value.accountId,
          UserEmail: order.value.email,
          TerminalMode: order.value.terminalMode,
          PublicId: order.value.terminalPublicId,
          TerminalName: order.value.terminalName,
          Amount: order.value.amount,
          Currency: order.value.currency,
          InvoiceId: order.value.orderNumber,
          URL: window.location.origin,
        });
      }

      isOrderLoaded.value = !!id && !!order.value;
      isCharity.value = order.value?.terminalInfo.IsCharity;
      showProcess.value = true;

      if (order.value?.paid) {
        await onSuccess(
          PaymentMethodEnum.Card,
          String(order.value.transactionId)
        );
      }
    }

    async function changeLocale(locale: string): Promise<void> {
      isOrderLoaded.value = false;
      const lowerCasedLocale = locale.toLowerCase();
      let lang = null;
      if (
        lowerCasedLocale.includes("ru") ||
        lowerCasedLocale == "kk" ||
        lowerCasedLocale == "uz"
      ) {
        lang = Lang.Ru;
      } else if (lowerCasedLocale == "kk-kz") {
        lang = Lang.Kz;
      } else if (lowerCasedLocale == "es-mx") {
        lang = Lang.Es;
      } else {
        lang = Lang.En;
      }
      if (app) {
        const changeLocale =
          app.appContext.config.globalProperties.changeLocale;
        if (changeLocale) {
          await changeLocale(lang);
          nextTick(() => {
            isOrderLoaded.value = true;
          });
        }
      }
    }

    async function onSuccess(
      paymentMethod: PaymentMethodEnum,
      transactionId?: string
    ) {
      if (paymentMethod === PaymentMethodEnum.Spei) {
        showProcess.value = false;
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnSuccessPage);
        return;
      }

      if (
        transactionId &&
        paymentMethod === PaymentMethodEnum.Card &&
        runtimeConfiguration?.showTransactionDetails
      ) {
        showProcess.value = false;
        await getTransactionInfo(transactionId);
      }

      showProcess.value = false;
      successPaymentMethod.value = paymentMethod;
      failPaymentMethod.value = null;
      loader?.hideLoader();
      window.scrollTo(0, 0);
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnSuccessPage);
    }

    async function getTransactionInfo(transactionId: string) {
      try {
        transactionInfo.value =
          (await appService?.getTransactionInfo(transactionId))?.Model || null;
        transactionInfo.value = {
          ...transactionInfo.value,
          CardType:
            transactionInfo.value?.CardType &&
            (cardMap[transactionInfo.value.CardType] ||
              transactionInfo.value.CardType?.toLowerCase()),
        };
        localizedAuthDate.value = transactionInfo?.value?.AuthDate
          ? new Date(transactionInfo?.value?.AuthDate)
              .toLocaleDateString(locale, {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
              .replaceAll("/", ".")
          : null;
        // eslint-disable-next-line no-empty
      } catch {}
    }

    function onFail(paymentMethod: PaymentMethodEnum): void {
      if (paymentMethod === PaymentMethodEnum.Spei) {
        showProcess.value = false;
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnErrorPage);
        return;
      }

      failPaymentMethod.value = paymentMethod;
      successPaymentMethod.value = null;
      loader?.hideLoader();
      window.scrollTo(0, 0);
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.OnErrorPage);
    }

    function onChange(): void {
      successPaymentMethod.value = null;
      failPaymentMethod.value = null;
    }

    return {
      order,
      successPaymentMethod,
      failPaymentMethod,
      showProcess,
      showContent,
      isCharity,
      onSuccess,
      onFail,
      onChange,
      showTransactionDetails: runtimeConfiguration?.showTransactionDetails,
      transactionInfo,
      isOpenDetails,
      localizedAuthDate,
    };
  },
});
</script>
<style src="./Home.scss" scoped lang="scss"></style>
