import { HttpClient } from "@cloudpayments/api-client";

export class FeatureApiService {
  private client: HttpClient = new HttpClient(
    `${window.location.origin}/feature-api/toggles`
  );
  private readonly timeout: number = 15;
  private readonly toggleMap: Map<string, boolean> = new Map<string, boolean>();

  public async loadToggleData(path: string, filter?: string) {
    const url = FeatureApiService.getUrl(path, filter);
    const result = await this.isToggleEnabled(url);

    this.toggleMap.set(url.search, result);
  }

  public getToggleData(path: string, filter?: string) {
    const url = FeatureApiService.getUrl(path, filter);

    return this.toggleMap.get(url.search);
  }

  private async isToggleEnabled(url: URL) {
    const result = await this.getWithTimeout(url);

    if (typeof result != "boolean") {
      return result.data[0]?.value ?? false;
    }

    return result;
  }

  private async getWithTimeout(url: URL) {
    try {
      const result = await Promise.race<
        IFeatureApiResponse<IFeatureApiToggle[]>
      >([
        new Promise((res, rej) =>
          setTimeout(() => rej(false), this.timeout * 1000)
        ),
        this.client.get<IFeatureApiResponse<IFeatureApiToggle[]>>(
          `${url.search}`
        ),
      ]);

      return result;
    } catch (e) {
      return false;
    }
  }

  private static getUrl(path: string, filter?: string) {
    const url = new URL(`${window.location.origin}`, window.location.origin);
    url.searchParams.append("path", path);
    if (filter != null) {
      url.searchParams.append("filter", filter);
    }

    return url;
  }
}

export interface IFeatureApiToggle {
  name: string;
  path: string;
  value: boolean;
}

export interface IFeatureApiResponse<T> {
  statusCode: number;
  message: string;
  data: T;
}
